import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../../Assets/Styles/AboutUs.css'
import AboutUsBackground from '../../Assets/Images/AboutUs/Aboutus.jpg';
import axios from 'axios';
const Section1 = () => {
  const [Useabout, setAbout] = useState([]);
  useEffect(() => {
    axios.get("home_about/get")
        .then((response) => {
            setAbout(response.data.responseData);
        })
        .catch((error) => {
            console.error("There was an error fetching the data!", error);
        });
}, []);
  return (
    <>
      <Container fluid >
        <Row>
          <img src={AboutUsBackground} className='img-fluid position-relative p-0' style={{marginTop: '-5px'}}/>
          <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8} className="text-white position-absolute ps-lg-4  my-lg-5 ">
            <h1 className="textheading fw-bolder">
              about <span className="highlight">us</span>
            </h1>
            <p className='ps-lg-4'>
            <div dangerouslySetInnerHTML={{ __html: Useabout[0]?.description }}></div>
              {/* Bilz Vibration Technology AG is based in Leonberg, Germany. Bilz has specialized in machine mounting technology with a focus on vibration isolation. Bilz is Europe‘s leading anti-vibration specialists supplying products and services to machine and equipment manufacturers as well as the automobile industry worldwide. Vibration isolation is always an issue when it comes to precision machining or measurement. 45 years of experience in the field of vibration technology enables Bilz to offer a wide range of solutions. */}
            </p>
          </Col>
        </Row>
      </Container>

    </>
  )
}

export default Section1

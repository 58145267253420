import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import axios from 'axios'; 
import '../../Assets/Styles/Home.css';

const Section5 = () => {
    const [Exhibition, setExhibition] = useState([]);

    useEffect(() => {
        axios.get("/exhibition/get")
            .then((response) => {
                setExhibition(response.data.responseData);
            })
            .catch((error) => {
                console.error("There was an error fetching the data!", error);
            });
    }, []);

    return (
        <Container fluid>
            <Row>
                <h1 className='text-secondary-emphasis textheading fw-bolder pt-5 px-5'>Exhibition</h1>
                {Exhibition.map((item) => (
                    <Col xs={12} sm={6} md={6} lg={4} xl={4} xxl={4} className="pt-3" key={item.id}>
                        <Card className="rounded-5 h-100 cardshadow">
                            <Card.Img variant="top" src={item.img} alt={item.name} className="px-3 pt-3" />
                            <Card.Body>
                                <Card.Title className="fw-bolder text-uppercase">{item.title}</Card.Title>
                                <Card.Text>{item.desc}</Card.Text>
                            </Card.Body>
                            <div className="d-flex justify-content-end pb-3 pe-4">
                                <Link 
                                    to={`/newsdetails/${item.id}`}  // Navigate to NewsDetails with item id
                                    state={{ exhibitionItem: item }}  // Pass the item as state
                                >
                                    <Button className="rounded-5 border-3 border-0 px-3 py-2 border learn_more">
                                        Learn more
                                    </Button>
                                </Link>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default Section5;

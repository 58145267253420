import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import SolutionCardImg from '../../Assets/Images/SolutionOverview/SolutionCardImg.png'
import '../../Assets/Styles/Solution/SolutionOverview.css'
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const Section2 = () => {

    const [SolutionAppCard, setSolutionAppCard] = useState([]);

    useEffect(() => {
        axios.get("implemented/get")
            .then((response) => {
                setSolutionAppCard(response.data.responseData);
            })
            .catch((error) => {
                console.error("There was an error fetching the data!", error);
            });
    }, []);

    const navigate = useNavigate();

    // const SolutionAppCard = [
    //     {
    //         name: 'Economical, long-term and trouble-free with a strong combination',
    //         title: 'Successful receiver insulation of a gear grinding machine with BiAir® diaphragm air springs and vibrating foundation',
    //         image: SolutionCardImg
    //     },
    //     {
    //         name: 'Economical, long-term and trouble-free with a strong combination',
    //         title: 'Successful receiver insulation of a gear grinding machine with BiAir® diaphragm air springs and vibrating foundation',
    //         image: SolutionCardImg
    //     },
    //     {
    //         name: 'Economical, long-term and trouble-free with a strong combination',
    //         title: 'Successful receiver insulation of a gear grinding machine with BiAir® diaphragm air springs and vibrating foundation',
    //         image: SolutionCardImg
    //     },
    // ];
    return (
        <>
            <Container fluid>
                <h1 className="fw-bold text-center textheading pt-5">
                    We have already <span className='highlight'>implemented these solutions</span>
                </h1>
                <Row className="position-relative mb-5 px-4">
                    {SolutionAppCard.map((member, index) => (
                        <Col xs={12} sm={6} md={6} lg={4} xl={4} xxl={4} className='pt-4'>
                            <Card className='rounded-5 h-100 cardshadows'>
                                <Card.Img variant="top" src={member.img} alt={member.name} className='pt-3 px-3' />
                                <Card.Body>
                                    <Card.Title className='fw-bolder text-uppercase'>{member.title}</Card.Title>
                                    <Card.Text className='text-justify text-capitalize lh-sm'>{member.desc}</Card.Text>
                                </Card.Body>
                                <div className="d-flex justify-content-end pb-3 pe-4">
                                    <Button className="rounded-5 border-3 border-0 px-3 py-2 border fw-medium learn_more" onClick={() => navigate("/ProductDetail")}>
                                        Learn more
                                    </Button>
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </>
    )
}

export default Section2

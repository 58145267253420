import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ProductImg1 from '../Assets/Images/Product/ProductImg1.png'
import ProductImg2 from '../Assets/Images/Product/ProductImg2.png'
import ProductImg3 from '../Assets/Images/Product/ProductImg3.png'
import ProductImg4 from '../Assets/Images/Product/ProductImg4.png'
import ProductImg5 from '../Assets/Images/Product/ProductImg5.png'
import ProductImg6 from '../Assets/Images/Product/ProductImg6.png'
import { useNavigate } from "react-router-dom";
import '../Assets/Styles/Product.css'

const Product = () => {

  const navigate = useNavigate();
  
  const ProductCard = [
    {
      name: 'Insulation plate type B6',
      title: 'Extremely heavy-duty insulation plate with the highest level of level consistency. For very heavy and long-bed machines.',
      image: ProductImg1
    },
    {
      name: 'Insulation plate type B6',
      title: 'Extremely heavy-duty insulation plate with the highest level of level consistency. For very heavy and long-bed machines.',
      image: ProductImg2
    },
    {
      name: 'Insulation plate type B6',
      title: 'Extremely heavy-duty insulation plate with the highest level of level consistency. For very heavy and long-bed machines.',
      image: ProductImg3
    },
    {
      name: 'Insulation plate type B6',
      title: 'Extremely heavy-duty insulation plate with the highest level of level consistency. For very heavy and long-bed machines.',
      image: ProductImg4
    },
    {
      name: 'Insulation plate type B6',
      title: 'Extremely heavy-duty insulation plate with the highest level of level consistency. For very heavy and long-bed machines.',
      image: ProductImg5
    },
    {
      name: 'Insulation plate type B6',
      title: 'Extremely heavy-duty insulation plate with the highest level of level consistency. For very heavy and long-bed machines.',
      image: ProductImg6
    },
    {
      name: 'Insulation plate type B6',
      title: 'Extremely heavy-duty insulation plate with the highest level of level consistency. For very heavy and long-bed machines.',
      image: ProductImg2
    },
    {
      name: 'Insulation plate type B6',
      title: 'Extremely heavy-duty insulation plate with the highest level of level consistency. For very heavy and long-bed machines.',
      image: ProductImg3
    },
    {
      name: 'Insulation plate type B6',
      title: 'Extremely heavy-duty insulation plate with the highest level of level consistency. For very heavy and long-bed machines.',
      image: ProductImg4
    }
  ];
  return (
    <>
      <Container fluid className='ProductsCard pt-4'>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <h1 className='px-lg-2 py-lg-4 fw-semibold textheading'>
              Insulation <span className="highlight">Panels</span>
            </h1>
          </Col>
        </Row>  
        <Row className=''>
          {ProductCard.map((member, index) => ( 
            <Col xs={12} sm={6} md={6} lg={4} xl={4} xxl={4} className='pt-1 pb-3 pe-3'>
              <Card className='rounded rounded-5 cardshadow'>
                <Card.Img variant="top" src={member.image} alt={member.name} className='pt-3 px-3' />
                <Card.Body>
                  <Card.Title className='fw-bold text-uppercase'>{member.name}</Card.Title>
                  <Card.Text className='text-justify text-dark lh-sm'>{member.title}</Card.Text>
                </Card.Body>
                <div className="d-flex justify-content-end pb-3 pe-4">
                  <Button className="rounded-5 border-3 border-0 px-3 py-2 border fw-medium learn_more" onClick={() => navigate("/ProductDetail")}>
                    Learn more
                  </Button>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  )
}

export default Product

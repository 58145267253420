import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import '../../Assets/Styles/JobOpportunity.css';
import internship from '../../Assets/Images/JobOpportunity/internship.png';
import jop from '../../Assets/Images/JobOpportunity/jop.png';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import ReCAPTCHA from 'react-google-recaptcha';

const Section3 = () => {
  const [activeTab, setActiveTab] = useState('tab1');
  const [file, setFile] = useState(null);
  const [captchaValue, setCaptchaValue] = useState(null); // Add captcha state
  const [errors, setErrors] = useState({});

  // Form fields for both forms
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [position, setPosition] = useState('');
  const [msg, setMsg] = useState('');
  const [img, setImg] = useState('');

  const onDrop = (acceptedFiles) => {
    setFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'application/pdf': [], 'image/jpeg': [], 'image/png': [] },
    maxSize: 25 * 1024 * 1024, // 25MB max file size
  });

  const openTab = (tabName) => {
    setActiveTab(tabName);
    setErrors({});
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const validateForm = () => {
    let newErrors = {};
    let isValid = true;

    if (!name.trim()) {
      newErrors.name = 'Name is required';
      isValid = false;
    }

    if (!phone.trim()) {
      newErrors.phone = 'Mobile No. is required';
      isValid = false;
    } else if (!/^\d{10}$/.test(phone)) {
      newErrors.phone = 'Mobile number must be exactly 10 digits';
      isValid = false;
    }

    if (!email.trim()) {
      newErrors.email = 'Email Id is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Invalid Email Id address';
      isValid = false;
    }

    if (!position.trim()) {
      newErrors.position = 'City is required';
      isValid = false;
    }

    if (!msg.trim()) {
      newErrors.msg = 'Message is required';
      isValid = false;
    }

    if (!captchaValue) {
      newErrors.captcha = 'Please complete the CAPTCHA';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('position', position);
        formData.append('msg', msg);

        if (file) {
          formData.append('img', img);
        }

        const response = await axios.post('apply_now/add', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status === 200) {
          console.log('Submitted data:', response.data);

          // Clear form fields
          setName('');
          setEmail('');
          setPhone('');
          setPosition('');
          setMsg('');
          setFile(null);
          setImg(null);
          setCaptchaValue(null);
          setErrors({});
          alert('Thank you! We will connect with you soon.');
        }
      } catch (error) {
        console.error('Error submitting data:', error.response || error);
      }
    }
  };
  // Job Form
  const validateForm1 = () => {
    let errors = {};
    let isValid = true;

    if (!name.trim()) {
      errors.name = 'Name is required';
      isValid = false;
    }

    if (!phone.trim()) {
      errors.phone = 'Mobile No. is required';
      isValid = false;
    } else if (!/^\d{10}$/.test(phone)) {
      errors.phone = 'Mobile number must be exactly 10 digits';
      isValid = false;
    }

    if (!email.trim()) {
      errors.email = 'Email Id is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Invalid Email Id address';
      isValid = false;
    }

    if (!position.trim()) {
      errors.position = 'City is required';
      isValid = false;
    }

    if (!msg.trim()) {
      errors.msg = 'Message is required';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit1 = async (a) => {
    a.preventDefault();
    if (validateForm1()) {
      try {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('position', position);
        formData.append('msg', msg);

        if (file) {
          formData.append('img', img);
        }

        const response = await axios.post('job/add', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status === 200) {
          console.log('Submitted data job:', response.data);
          setName('');
          setEmail('');
          setPhone('');
          setPosition('');
          setMsg('');
          setImg(null);
          setErrors({});
          alert('Thank you! We will connect with you soon.');
        }
      } catch (error) {
        console.error('Error submitting data:', error.response || error);
      }
    }
  };
  return (
    <Container fluid>
      <Row className="mt-5 px-md-5 mb-3 pb-5" id="skills">
        <Col xs={12} className="mySkills">
          <h1 className="mx-5 my-4 fw-bolder textheading">
            Apply <span className="highlight">Now</span>
          </h1>
          <div id="skillsContainer" className="cardshadow">
            <div className="tab mb-3 ">
              <button
                className={`tabLinks ${activeTab === 'tab1' ? 'active' : ''}`}
                onClick={() => openTab('tab1')}
              >
                <div className="d-flex flex-row justify-content-center align-items-center">
                  <img src={internship} className="img-fluid" alt="Internship" />
                  <h4 className="ms-4 mb-0">INTERNSHIP</h4>
                </div>
              </button>
              <span className="separator text-white mx-3">|</span>
              <button
                className={`tabLinks ${activeTab === 'tab2' ? 'active' : ''}`}
                onClick={() => openTab('tab2')}
              >
                <div className="d-flex flex-row justify-content-center align-items-center">
                  <img src={jop} className="img-fluid" alt="Job" />
                  <h4 className="ms-3 mb-0">Job</h4>
                </div>
              </button>
            </div>

            {/* Form for Internship */}
            {activeTab === 'tab1' && (
              <form onSubmit={handleSubmit}>
                <div id="tab1" className="tabContent p-3 p-md-5">
                  <Row className="mb-3">
                    <Col xs={12} sm={6} className="mb-3 mb-sm-0">
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        className="rounded-5 px-3 py-2 shadows"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      {errors.name && <span className="error text-danger">{errors.name}</span>}
                    </Col>
                    <Col xs={12} sm={6}>
                      <Form.Control
                        type="text"
                        placeholder="Mobile Number"
                        className="rounded-5 px-3 py-2 shadows"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      {errors.phone && <span className="error text-danger">{errors.phone}</span>}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={12} sm={6} className="mb-3 mb-sm-0">
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        className="rounded-5 px-3 py-2 shadows"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {errors.email && <span className="error text-danger">{errors.email}</span>}
                    </Col>
                    <Col xs={12} sm={6}>
                      <Form.Control
                        type="text"
                        placeholder="City"
                        className="rounded-5 px-3 py-2 shadows"
                        value={position}
                        onChange={(e) => setPosition(e.target.value)}
                      />
                      {errors.position && <span className="error text-danger">{errors.position}</span>}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <Form.Control
                        as="textarea"
                        placeholder="Message"
                        className="rounded-4 px-4 py-3 align-top shadows"
                        value={msg}
                        onChange={(e) => setMsg(e.target.value)}
                        rows={4}
                      />
                      {errors.msg && <span className="error text-danger">{errors.msg}</span>}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <div
                        {...getRootProps()}
                        className={`rounded-4 px-4 py-4 shadows text-center ${isDragActive ? 'bg-light border-primary' : 'bg-white border'
                          }`}
                        style={{ borderStyle: 'dashed', borderWidth: '2px' }}
                      >
                        <input {...getInputProps()} />
                        {file ? (
                          <p>{file.name}</p>
                        ) : (
                          <p className="mb-0">
                            Drag a file (Allowed File Types: pdf, jpeg, png; Maximum: 25MB)
                          </p>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <ReCAPTCHA
                        sitekey="6LdKjlwqAAAAAMM4PV64krFdtNiannAhVkCmzXbH" // Replace with your actual site key
                        onChange={handleCaptchaChange}
                      />
                      {errors.captcha && <p className='text-danger'>{errors.captcha}</p>}
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-end">
                    <Button
                      variant="primary"
                      className="mt-4 rounded-pill learn_more btnsubmit py-2 px-4"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            )}

            {/* Form for Job (identical for simplicity) */}
            {activeTab === 'tab2' && (
              <form onSubmit={handleSubmit1}>
                <div id="tab2" className="tabContent p-3 p-md-5">
                  <Row className="mb-3">
                    <Col xs={12} sm={6} className="mb-3 mb-sm-0">
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        className="rounded-5 px-3 py-2 shadows"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      {errors.name && <span className="error text-danger">{errors.name}</span>}
                    </Col>
                    <Col xs={12} sm={6}>
                      <Form.Control
                        type="text"
                        placeholder="Mobile Number"
                        className="rounded-5 px-3 py-2 shadows"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      {errors.phone && <span className="error text-danger">{errors.phone}</span>}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={12} sm={6} className="mb-3 mb-sm-0">
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        className="rounded-5 px-3 py-2 shadows"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {errors.email && <span className="error text-danger">{errors.email}</span>}
                    </Col>
                    <Col xs={12} sm={6}>
                      <Form.Control
                        type="text"
                        placeholder="City"
                        className="rounded-5 px-3 py-2 shadows"
                        value={position}
                        onChange={(e) => setPosition(e.target.value)}
                      />
                      {errors.position && <span className="error text-danger">{errors.position}</span>}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <Form.Control
                        as="textarea"
                        placeholder="Message"
                        className="rounded-4 px-4 py-3 align-top shadows"
                        value={msg}
                        onChange={(e) => setMsg(e.target.value)}
                        rows={4}
                      />
                      {errors.msg && <span className="error text-danger">{errors.msg}</span>}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <div
                        {...getRootProps()}
                        className={`rounded-4 px-4 py-4 shadows text-center ${isDragActive ? 'bg-light border-primary' : 'bg-white border'
                          }`}
                        style={{ borderStyle: 'dashed', borderWidth: '2px' }}
                      >
                        <input {...getInputProps()} />
                        {file ? (
                          <p>{file.name}</p>
                        ) : (
                          <p className="mb-0">
                            Drag a file (Allowed File Types: pdf, jpeg, png; Maximum: 25MB)
                          </p>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <ReCAPTCHA
                        sitekey="6LdKjlwqAAAAAMM4PV64krFdtNiannAhVkCmzXbH" // Replace with your actual site key
                        onChange={handleCaptchaChange}
                      />
                      {errors.captcha && <p className='text-danger'>{errors.captcha}</p>}
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-end">
                    <Button
                      variant="primary"
                      className="mt-4 rounded-pill learn_more btnsubmit py-2 px-4"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Section3;

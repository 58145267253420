import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import CardImg from '../Assets/Images/News/CardImg.png'
import '../Assets/Styles/News.css'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import NewsBanner from '../ComponentsPage/Banner/NewsBanner';
const News = () => {

    const navigate = useNavigate(); 

    const [Usenews, setNews] = useState([]);

    useEffect(() => {
        axios.get("News1/get")
            .then((response) => {
                setNews(response.data.responseData);
            })
            .catch((error) => {
                console.error("There was an error fetching the data!", error);
            });
    }, []);

    // const NewsCard = [
    //     {
    //         name: 'Photovoltaic system in operation',
    //         title: 'A few weeks ago we put our photovoltaic system into operation.The system comprises 297 solar panels on an area of ​​around 510 m². This covers around 65% of our total electricity needs.',
    //         image: CardImg
    //     },
    //     {
    //         name: 'Photovoltaic system in operation',
    //         title: 'A few weeks ago we put our photovoltaic system into operation.The system comprises 297 solar panels on an area of ​​around 510 m². This covers around 65% of our total electricity needs.',
    //         image: CardImg
    //     },
    //     {
    //         name: 'Photovoltaic system in operation',
    //         title: 'A few weeks ago we put our photovoltaic system into operation.The system comprises 297 solar panels on an area of ​​around 510 m². This covers around 65% of our total electricity needs.',
    //         image: CardImg
    //     },
    //     {
    //         name: 'Photovoltaic system in operation',
    //         title: 'A few weeks ago we put our photovoltaic system into operation.The system comprises 297 solar panels on an area of ​​around 510 m². This covers around 65% of our total electricity needs.',
    //         image: CardImg
    //     },
    //     {
    //         name: 'Photovoltaic system in operation',
    //         title: 'A few weeks ago we put our photovoltaic system into operation.The system comprises 297 solar panels on an area of ​​around 510 m². This covers around 65% of our total electricity needs.',
    //         image: CardImg
    //     },
    //     {
    //         name: 'Photovoltaic system in operation',
    //         title: 'A few weeks ago we put our photovoltaic system into operation.The system comprises 297 solar panels on an area of ​​around 510 m². This covers around 65% of our total electricity needs.',
    //         image: CardImg
    //     },
    // ];

 

    return (    
        <>
            <NewsBanner/>
            <Container Fluid className='NewCard'>
                <Row className="px-3 py-5">
                    {Usenews
                    .filter((Usenews) => Usenews.isActive)
                    .map((newsdata, index) => (
                        <Col xs={12} sm={6} md={6} lg={4} xl={4} xxl={4} className='pt-3'>
                            <Card className="rounded-5 cardshadow">
                                <Card.Img variant="top" src={newsdata.img} alt={newsdata.img} className="px-3 py-3 rounded-5" />
                                <Card.Body>
                                    <Card.Title className="fw-bolder text-uppercase">{newsdata.title}</Card.Title>
                                    <Card.Text>{newsdata.desc}</Card.Text>
                                </Card.Body>
                                <div className="d-flex justify-content-end pb-3 pe-4">
                                    <Button className="rounded-5 border-3 border-0 px-3 py-2 border fw-medium learn_more" onClick={() => navigate(`/NewsDetails/${newsdata.id}`)}>
                                        Learn more
                                    </Button>
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </>
    )
}

export default News
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../../Assets/Styles/Solution/SolutionApplication.css';
import SolutionBanner from '../../ComponentsPage/Banner/SolutionBanner';
import axios from 'axios';

const SolutionApplication = () => {
  const [activeLink, setActiveLink] = useState(null);
  const [applicationCat, setApplicationCat] = useState([]);
  const [useApplication, setUseApplication] = useState([]);
  const [allApplications, setAllApplications] = useState([]);  

  useEffect(() => {
    axios.get('ServiceName/get')
      .then((response) => {
        setApplicationCat(response.data.responseData);
      })
      .catch((error) => {
        console.error('There was an error fetching the application categories!', error);
      });

    axios.get('ServiceDetail/get')
      .then((response) => {
        setAllApplications(response.data.responseData);  
        setUseApplication(response.data.responseData);   
      })
      .catch((error) => {
        console.error('There was an error fetching all the implemented data!', error);
      });
  }, []);

  const fetchImplementedData = (id) => {
    axios.get(`ServiceDetail/get?productId=${id}`)  
      .then((response) => {
        setUseApplication(response.data.responseData);
      })
      .catch((error) => {
        console.error('There was an error fetching the implemented data!', error);
      });
  };

  const handleSelect = (eventKey, id) => {
    setActiveLink(eventKey);
    fetchImplementedData(id); 
  };

  return (
    <>
      <SolutionBanner />
      <Container fluid className="bg-SolutionApplication pt-2 pb-5">
        <Row className="justify-content-center">
          <Col xs={12}>
            <div className="d-flex justify-content-around application-tab">
              <Navbar variant="light" expand="md">
                <Navbar id="responsive-navbar-nav">
                  {applicationCat.map((a, index) => (
                    <Nav
                      key={index}
                      className="mx-auto align-items-center fw-bolder"
                      activeKey={activeLink}
                      onSelect={(eventKey) => handleSelect(eventKey, a.id)}  
                    >
                      <Nav.Link
                        eventKey={`link-${index}`}
                        className={`text-white ${activeLink === `link-${index}` ? 'active-link' : ''} cardtitle text-uppercase px-5`}
                      >
                        {a.title}
                      </Nav.Link>
                      {index !== applicationCat.length - 2 && <span className="mx-2 text-white">|</span>}
                    </Nav>
                  ))}
                </Navbar>
              </Navbar>
            </div>
          </Col>
        </Row>

        <Row>
          {useApplication.length > 0 ? (
            useApplication.map((a, index) => (
              <Col xs={12} sm={6} md={6} lg={4} key={index} className="pt-4">
                <Card className="rounded rounded-5 h-100 cardshadow">
                  <Card.Img variant="top" src={a.img} alt={a.name} className="pt-3 px-3" />
                  <Card.Body>
                    <Card.Title className="fw-bold text-uppercase">{a.title}</Card.Title>
                    <Card.Text className="text-justify text-capitalize lh-sm">{a.description}</Card.Text>
                  </Card.Body>
                  <div className="d-flex justify-content-end pb-3 pe-4">
                    <Button className="rounded-5 border-3 border-0 px-3 py-2 border fw-medium learn_more">
                      Learn more
                    </Button>
                  </div>
                </Card>
              </Col>
            ))
          ) : (
            <Col xs={12} className="text-center pt-4">
              <p>No data available. Please select a category.</p>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default SolutionApplication;
